@import "~@/styles/variables.scss";









































































































.img {
    width: 100%;
    max-width: 200px;
}
