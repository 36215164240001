@import "~@/styles/variables.scss";


























































































































































































.upload {
  height: 500px;

  .upload-content {
    .el-upload__tip {
      display: block;
      height: 30px;
      line-height: 30px;
    }

    ::v-deep {
      .el-upload--picture-card {
        width: 128px;
        height: 128px;
        margin: 3px 8px 8px 8px;
        border: 2px dashed #c0ccda;
      }

      .el-upload-list--picture {
        margin-bottom: 20px;
      }

      .el-upload-list--picture-card {
        .el-upload-list__item {
          width: 128px;
          height: 128px;
          margin: 3px 8px 8px 8px;
        }
      }
    }
  }
}
